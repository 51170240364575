a:hover,
a:focus {
  text-decoration: none !important;
  color: dodgerblue;
}
.euiHeaderSectionItem:after {
  top: 0;
}


main {
  overflow: hidden !important;
  background-color: black;
}